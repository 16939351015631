import React from 'react';
import {FooterWrapper, PageWrapper, RightPart} from './styles';
import {LogoSection} from '../Auth/LogoSection';
import {ForgotPasswordFormEmail} from './ForgotPasswordForm';
import {useForgotPasswordT} from '../../hooks/auth';

type ForgotPasswordPropsT = {
  forgotData: useForgotPasswordT;
};

export const ForgotPassword: React.FC<ForgotPasswordPropsT> = ({forgotData}) => {
  return (
    <PageWrapper>
      <LogoSection />
      <RightPart>
        {forgotData?.isEmailReset && <ForgotPasswordFormEmail forgotData={forgotData} />}
        <FooterWrapper showFootNote />
      </RightPart>
    </PageWrapper>
  );
};
