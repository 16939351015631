import React, {useState} from 'react';
import {useSendMagicLinkRes} from '../../hooks/magicLink';
import {
  MagicLinkFormContainer,
  MagicLinkFormWrapper,
  PageWrapper,
  SuccessTitle,
  FormTitle,
  SuccessBlockWrapper,
  DescrText,
  SubmitBtn,
  RightPart,
  FooterWrapper,
  ErrorLine,
  StyledLine,
  StyledOr,
  LoginLinkButton,
} from './styles';
import Input, {FormItem} from '../../ui-kit/Input';
import {Form} from 'antd';
import {MagicLinkFields} from '../../types/auth';
import {route} from '../../constants/routes';
import {validateEmail} from '../../helpers/helpers';
import {LogoSection} from '../Auth/LogoSection';

type MagicLinkPageT = useSendMagicLinkRes;

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
};

export const MagicLinkPage: React.FC<MagicLinkPageT> = ({onSend, success, error}) => {
  const [form] = Form.useForm();
  const [stateButton, setStateButton] = useState(true);

  const onFinish = async (values: any) => {
    await onSend(values);
  };

  const handleChange = (inputValue: {email: string}): void => {
    setStateButton(!validateEmail(inputValue?.email.toLowerCase()));
  };

  return (
    <PageWrapper>
      <>
        <LogoSection />
        <RightPart>
          {!success ? (
            <MagicLinkFormContainer>
              <MagicLinkFormWrapper
                form={form}
                layout={'vertical'}
                onFinish={onFinish}
                onValuesChange={handleChange}
                validateMessages={validateMessages}>
                <FormTitle>Promo Codes Client Portal</FormTitle>
                <DescrText>
                  Did you lose the Magic Link to your account? Don’t worry. Just enter your email address below. We will
                  send you a Magic Link to retrieve your access.
                </DescrText>
                <FormItem
                  rules={[
                    {required: true},
                    {
                      type: 'email',
                    },
                  ]}
                  name={[MagicLinkFields.email]}>
                  <Input placeholder="Enter email" />
                </FormItem>
                {error && <ErrorLine>{error}</ErrorLine>}
                <Form.Item>
                  <SubmitBtn htmlType="submit" disabled={stateButton}>
                    Send Me a Magic Link
                  </SubmitBtn>
                </Form.Item>
                <StyledLine>
                  <StyledOr>Or</StyledOr>
                </StyledLine>
                <LoginLinkButton to={route.login.path}>Log in With Email</LoginLinkButton>
              </MagicLinkFormWrapper>
            </MagicLinkFormContainer>
          ) : (
            <SuccessSend />
          )}
          <FooterWrapper showFootNote />
        </RightPart>
      </>
    </PageWrapper>
  );
};

const SuccessSend = () => {
  return (
    <SuccessBlockWrapper>
      <SuccessTitle>We’ve sent you the Magic Link!</SuccessTitle>
      <DescrText>Your Magic Link should arrive in your inbox momentarily. Please keep an eye out for it!</DescrText>
    </SuccessBlockWrapper>
  );
};
