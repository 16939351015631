import React from 'react';
import {KIT_THEME} from '../theme/theme';
import {themes} from '../theme/theme';
import styled from 'styled-components';

export const FootNote = () => {
  const jingle = KIT_THEME === themes.lite;
  if (jingle) return null;
  return (
    <StyledLink href="https://aliasticketing.com" target="_blank">
      Powered by <Icon />
    </StyledLink>
  );
};

const Icon = () => (
  <svg width="33" height="13" viewBox="0 0 33 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13.2451" y="0.644531" width="2.17007" height="7.95429" fill="#010002" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 0.644531H8.83008V10.5874V12.576H11.0001H15.4151V10.5874H11.0001V0.644531Z"
      fill="#010002"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.17007 0.644531H0V2.6331V6.61025V8.59882V12.576H2.17007V8.59882H4.34014V12.576H6.58503V8.59882V6.61025V2.6331V0.644531H4.34014H2.17007ZM4.34014 2.6331H2.17007V6.61025H4.34014V2.6331Z"
      fill="#010002"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9044 0.644531H17.7344V2.6331V6.61025V8.59882V12.576H19.9044V8.59882H22.0745V12.576H24.3194V8.59882V6.61025V2.6331V0.644531H22.0745H19.9044ZM22.0745 2.6331H19.9044V6.61025H22.0745V2.6331Z"
      fill="#010002"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6603 0.644531H26.4902V6.61025V8.59882H28.6603L30.7555 8.59882V10.656H26.4902V12.6445L30.7555 12.6445H33.0004V10.656V8.59882V6.61025H30.7555L28.6603 6.61025V2.6331L30.7555 2.6331V4.62167H33.0004V2.6331V0.644531H28.6603Z"
      fill="#010002"
    />
  </svg>
);

const StyledLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  gap: 6px;
  color: ${({theme}) => theme.palette.common.black} !important;
`;
