import React from 'react';
import {FooterText, FooterWrapper, ReshareAccessButton, FooterCenterText, Actions} from './styles';
import {TITLE} from '../constants';
import {FootNote} from './FootNote';
import {KIT_THEME, themes} from '../theme/theme';

export type FooterPropsT = {
  text?: string;
  showReshare?: boolean;
  buttonText?: string;
  onReshare?: () => void;
  showAdditional?: boolean;
  actionText?: string;
  handleAction?: () => void;
  showFootNote?: boolean;
  rightAction?: React.ReactNode;
};

export const Footer = (props: FooterPropsT) => {
  const {text, showReshare, buttonText, onReshare, showAdditional, actionText, handleAction, showFootNote} = props;
  const baseText = `© ${TITLE} ${new Date().getFullYear()}.`;
  const jingle = KIT_THEME === themes.lite;

  return (
    <FooterWrapper {...props} zIndex={2}>
      {showReshare ? (
        <>
          {<FooterText>{showFootNote && !jingle ? <FootNote /> : text || baseText}</FooterText>}
          <Actions>
            {showAdditional && <ReshareAccessButton onClick={handleAction}>{actionText}</ReshareAccessButton>}
            <ReshareAccessButton onClick={onReshare}>{buttonText}</ReshareAccessButton>
            {props.rightAction}
          </Actions>
        </>
      ) : (
        <FooterCenterText>{showFootNote && !jingle ? <FootNote /> : text || baseText}</FooterCenterText>
      )}
    </FooterWrapper>
  );
};
